.section-headline {
	@extend %font-gotham-bold;
	@include font-size(30px);
	@include letter-spacing(10);
	line-height: 1;
	margin: 0 auto;
	// padding: 0 0 0.25em;
	padding: 0 0 0.75em;
	text-align: center;
	text-transform: uppercase;

	@media #{$mq-900-up} {
		font-size: 5.6vw;
	}

	@media #{$mq-1400-up} {
		@include font-size(90px);
	}
}

.sub-headline {
	@extend %font-gotham-bold;
	@include font-size(18px);
	@include letter-spacing(50);
	line-height: 1.1;
	margin: 0;
	padding: 0 0 0.5em;
	text-align: center;
	text-transform: uppercase;

	@media #{$mq-900-up} {
		font-size:2.7vw;
	}

	@media #{$mq-1400-up} {
		@include font-size(45px);
	}
}

.text-headline {
	@extend %font-gotham-bold;
	@include font-size(30px);
	@include letter-spacing(50);
	line-height: 1.1;
	margin: 0;
	padding: 0 0 1em;
	text-align: center;
	text-transform: uppercase;

	@media #{$mq-900-up} {
		font-size:2.7vw;
	}

	@media #{$mq-1400-up} {
		@include font-size(45px);
	}
}

h4 {
	@extend %font-gotham-bold;
	@include font-size(22px);
	@include letter-spacing(50);
	line-height: 1.1;
	margin: 0;
	padding: 0 0 1em;
	text-align: inherit;
	text-transform: uppercase;

	@media #{$mq-900-up} {
		font-size:2.7vw;
	}

	@media #{$mq-1400-up} {
		@include font-size(33px);
	}
}

.graf-headline {
	@extend %font-gotham-bold;
	line-height: 1.1;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
}

%base-graf {
	@extend %font-gotham;
	@include font-size(20px);
	@include letter-spacing(10);
	line-height: 1.2;
	margin: 0;
	padding: 0 0 1em;

	@media #{$mq-900-up} {
		font-size: 2vw;
	}

	@media #{$mq-1400-up} {
		@include font-size(30px);
	}
}

.copy ul {
	@extend %base-graf;
	line-height: 1.35;
	padding: 0 0 0 1em;

	li {
		padding-bottom: 0.75em;
	}
}

.article-body p {
	@extend %base-graf;
}

.article-content {
	.article-h1 {
		@extend %font-gotham-medium;
		@include font-size(36px);
		@include letter-spacing(10);
		line-height: 1.125;
		margin: 0;
		padding: 0.5em 0;

		@media #{$mq-900-up} {
			font-size: 3.5vw;
		}	

		@media #{$mq-1400-up} {
			@include font-size(52px);
		}	
	}
}