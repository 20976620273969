html {
	box-sizing: border-box;
	font-size: 100%;

	@media #{$mq-900-up} {
		min-height: 100%;
		position: relative;
	}
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

input[type="button"], input[type="submit"], input[type="reset"] {
	-webkit-appearance: none;
}

body {
	font-family: 'Gotham Book', Gotham-Book, Helvetica, Arial, sans-serif;
	@extend %font-gotham;
	background: $base-page-background;
	color: $base-font-color;
	line-height: 1.3;
	margin: 0;
	overflow-x: hidden;
	padding: 0;

	@media #{$mq-900-up} {
		// margin: 0 0 16rem;
	}
}

a {
	color: $base-link-color;
}

.outer-content {
	background-color: $color-purple;
	margin: 0 auto;
	padding: 4.25rem 0 0;

	@media #{$mq-900-up} {
		padding: 0;
	}
	// .content-wrapper {
	// 	margin: 0 auto;
	// 	max-width: $max-width;
	// 	padding: 0;
	// }
}

.content-wrapper {
	margin: 0 auto;
	max-width: $max-width;
	padding: 0;
}

img {
	display: block;
	height: auto;
	max-width: 100%;
	position: relative;
}

video {
	height: 100%;
	max-width: 100%;
	width: 100%;
}

.wp-video {
	width: 100% !important;
}

.article-h1 {
	margin: 0;
}

%stripey-border {
	// background-image:
	// 	repeating-linear-gradient(45deg, $color-stripes, $color-stripes 2rem, $color-blue-light 2rem, $color-blue-light 4rem, $color-stripes 4rem),
	// 	repeating-linear-gradient(135deg, $color-stripes, $color-stripes 2rem, $color-blue-light 2rem, $color-blue-light 4rem, $color-stripes 4rem),
	// 	repeating-linear-gradient(-135deg, $color-stripes, $color-stripes 2rem, $color-blue-light 2rem, $color-blue-light 4rem, $color-stripes 4rem),
	// 	repeating-linear-gradient(-45deg, $color-stripes, $color-stripes 2rem, $color-blue-light 2rem, $color-blue-light 4rem, $color-stripes 4rem);
	// background-size: 1.5rem 100%, 100% 1.5rem, 1.5rem 100% , 100% 1.5rem;
	// background-position: 0 0, 0 0, 100% 0, 0 100%;
	// background-repeat: no-repeat;

	@media #{$mq-900-down} {
		// background-size: 1rem 100%, 100% 1rem, 1rem 100% , 100% 1rem;
	}
}

%btn {
	@extend %font-gotham-bold;
	@include font-size(18px);
	@include letter-spacing(100);
	// background-color: $color-blue-med;
	border: 0;
	border-radius: 2em;
	color: $color-white;
	cursor: pointer;
	display: block;
	line-height: 1;
	margin: 0;
	padding: 0.75em 1.5em;
	text-decoration: none;
	text-transform: uppercase;
	transition: background-color $trans-duration ease, color $trans-duration ease;

	@media #{$mq-900-up} {
		font-size: 2vw;
	}

	@media #{$mq-1400-up} {
		@include font-size(30px);
	}

	&:hover,
	&:focus,
	&:active {
		// background-color: $color-cream;
		color: $color-blue-dark;
	}
}