@use 'sass:math';

@function calculateRem($size) {
	// $remSize: $size / 16px;
	$remSize: math.div($size, 16px);
	@return #{$remSize}rem;
}

@function calculateLetterSpacing($size) {
	// $milliEms: ($size / 1000);
	$milliEms: math.div($size, 1000);
	@return #{$milliEms}em;
}

@mixin font-size($size) {
	font-size: $size; //Fallback in px
	font-size: calculateRem($size);
}

@mixin letter-spacing($size) {
	letter-spacing: calculateLetterSpacing($size);
}
